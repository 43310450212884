html, body {
    height: 100%;
    margin: 0;
    padding: 0;
    overflow-x: hidden;
}

body {
    /* background: #312244; */
    /* background: rgb(0, 100, 102);
    background: linear-gradient(rgba(0, 100, 102, .05), rgba(33, 47, 69, .05), rgba(77, 25, 77, .05)),
        url(images/long_winding_road_anime_transparent_upscaled.png); */
    background: rgb(131, 58, 180);
        background: linear-gradient(60deg, rgba(131, 58, 180, 1) 0%, rgba(28, 177, 142, 0.5411414565826331) 41%, rgba(69, 70, 252, 1) 100%),
        url(images/long_winding_road_anime_transparent_upscaled.png);
        margin: 0;
    background-repeat: no-repeat;
    background-attachment: fixed;
    /* background-image: url(images/long_winding_road_anime_transparent_upscaled.png); */
    background-size: cover;
    /* background-size:auto; */
    /* font-family: "Play", sans-serif; */
    overflow-x: hidden;
    overflow-y: auto;
}

h1 {
    font-family: 'Unkempt', cursive;
    color: #630a7b;
    text-shadow: -1px -1px 0 #fff, 1px -1px 0 #fff, -1px 1px 0 #fff, 1px 1px 0 #fff;

}

p {
    /* color: #630a7b; */
    color: black;
    font-size: 22px;
    /* font-family: "Play", sans-serif; */
    /* font-family: "Shadows Into Light", cursive; */
    /* font-weight: 700; */
    /* text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000; */
    font-family: "Open Sans", sans-serif;
}

a {
    /* font-family: "Acme", sans-serif; */
    color: white !important;
}

a:hover {
    color: #4d194d !important;
}

h2 {
    color: #006466;
    font-size: 40px;
    /* text-shadow: -1px -1px 0 #fff, 1px -1px 0 #fff, -1px 1px 0 #fff, 1px 1px 0 #fff; */
}

.socialLinks {
    font-size: 2vh;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    padding: 10px;
}

.socialLinks img {
    height: 30px;
    width: auto;
}

.instagramLogo {
    width: 50px;
    border-radius: 5px;
}

.gitHubLogo {
    height: 40px;
    padding-top: 5px;
    padding-right: 10px;
}

.linkedInLogo {
    background: white;
    padding: 3px;
    border-radius: 5px;
    /* margin-left: 300px; */
    width: 50px;
    border-radius: 0;
}

.tikTokLogo {
    width: 50px;
}


/* .gitHubLogo img {
    height: 100px;
}

.instagramLogo {
    margin-right: 300px;
} */

.socialLinks a {
    text-decoration: none;
    /* color: white; */
}

.logo {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
}

.skcircle {
    /* width: 10vw;
    height: 10vw; */

    width: 12vh; 
    height: 12vh;
    background: #065a60;
    border-radius: 50%;
}

/* .k {
    font-family: 'Lakki Reddy', cursive;
    font-weight: 1000;
    color: black;
    background: #065a60;
    border-radius: 50%;
    width: 12vh;
    height: 12vh;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 10vh;
} */

.name {
    font-size: 13vh;
}

.nameLink {
    text-decoration: none;
}

.mainBlerb {
    /* display: flex;
    justify-items: center; */
    display: block;
    margin-left: 5vw;
    /* margin-right: auto; */
    font-size: 35px;
}

table img {
    width: 20vw;
    height: auto;
}

/* new .topNav code: */
.topNav {
    background: #0b525b;
    border: 2px #4d194d;
}

.topNav ul {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: space-around;
}

.topNav li {
    flex-grow: 1;
    text-align: center;
}

.topNav a {
    text-decoration: none;
    color: white;
    text-shadow: -1px -1px 0 #4d194d, 1px -1px 0 #4d194d, -1px 1px 0 #4d194d, 1px 1px 0 #4d194d;
    /* font-family: "Amatic SC", sans-serif; */
    font-family: "Acme", sans-serif;
    font-size: 28px;
    font-weight: 400;
    font-style: normal;
    display: block;
    padding: 10px;
}

.topNav a:hover {
    color: #4d194d !important;
}


.korchie2transback {
    margin: 20px;
    width: 40%;
    height: 40%;
    transform: scaleX(-1);
}


.korchieKrittersLogo {
    width: 30vw;
    max-width: 400px;
    border-radius: 0;
}

.container2 {
    padding-top: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 100px;
    background: #620a7b7d;
    width: 40%; /* Adjusts the width to be 80% of the parent container */
    margin: 0 auto; /* Centers the div horizontally */
    box-sizing: border-box; /* Includes padding and border in the element's total width */
    border-radius:  70px;

}

.container2 a {
    text-decoration: none;
}

.container2 h1 {
    text-shadow: -.5px -.5px 0 #fff, .5px -.5px 0 #fff, -.5px .5px 0 #fff, .5px .5px 0 #fff;
    font-family: "Mynerve", cursive;
    /* text-shadow: none; */
    font-weight: 1000;

}

.container2 h2 {
    color: white;
    font-family: "Mynerve", cursive;
}

.kKLogoContainer {
    display: flex;
    align-items: flex-end;
}

.motorcyclePic {
    margin-right: 30px;
}

.amBlerb {
    /* margin-top: 55px; */
    margin-left: 30px;
    margin-right: 30px;
}

.amContainer2 {
    display: flex;
    align-items: flex-start;
    align-items: center;
}

.amContainer4 {
    display: flex;
}

.amContainer5 {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.amContainer5 p {
    margin-left: 20px;
    margin-right: 20px;
}


.amContainer3 {
    margin-left: 20px;
    margin-right: 20px;
}

.hobbiesBlerb {
    margin-left: 30px;
    margin-right: 30px;
    margin-top: 30px;
}

.homepageContainer {
    display: flex;
    justify-content: space-evenly;
}

/* .homepageWords {
    display: block;
    margin-left: 20px;
    margin-right: 20px;
    margin-top: 30px;
} */

.homepageWords {
    display: flex;
    flex-direction: column;
    margin-top: 3vh;
    margin-left: 3vw;
}

.homepageBlerb {
    font-family: "Rock Salt", cursive;
    font-weight: 400;
    font-style: normal;
    font-size: 50px;
    /* font-weight: 600; */
    text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
    color:rgb(32, 32, 140)
}

.homepageBlerb2, .homepageBlerb3 {
    font-size: 45px;
    font-family: "Shadows into Light", cursive;
    font-weight: 700;
}

h1.store {
    font-family: 'Open Sans', sans-serif;
    color: yellow;
    /* text-shadow: none; */
    text-shadow: -.5px -.5px 0 #000, .5px -.5px 0 #000, -.5px .5px 0 #000, .5px .5px 0 #000;
    font-weight: 600;
}

h2.store {
    font-family: 'Open Sans', sans-serif;
}

.sk_transBack {
    border-radius: 8px;
    margin-left: 30px;
    /* max-width: 100%; */
    width: 25%;
    /* height: auto; */
}

.amContainer {
    /* display: flex;
    flex-direction: row;
    align-items: flex-start; */
    padding-bottom: 20px;
}

.motorcyclePic {
    height: 577px;
}

.korchieMotorcycle {
    width: 45%;
    display: block;
    margin: 0 auto; /*centers the image horizontally*/
    margin-right: 20px;
}

.portfolioBlerb {
    margin-left: 30px;
    margin-right: 30px;
    margin-bottom: 30px;
    margin-top: 52px;
    flex: 1; /* this allows the text to take up the remaining space */
    padding-right: 20px; /* Space between text and image */
}


img {
    border-radius: 4rem;
}

.edTitle {
    margin-left: 5px;
    /* margin-top: -180px; */
    color: #006466;
    font-weight: 700;
    text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
    /* background: rgba(141, 144, 144, 1);
    width: fit-content;
    border-radius: 5px; */
    font-size: 45px;
}

.ed {
    margin-left: 20px;
    font-size: 25px;
    /* color: #fffff0; */
    /* color: #bfc1c2; */
    font-style: italic;
}

.projectsTitle {
    margin-top: 20px;
    margin-right: 100px;
    margin-left: 5px;
    font-size: 35px;
    color: #006466;
    font-weight: 700;
    text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
}

.projects {
    font-size: 20px;
    margin-left: 75px;
    font-weight: 500;
    /* color: white; */
}

.projects a {
    color: rgba(141, 144, 144, 1);
}

.skillsTitle {
    margin-top: 20px;
    margin-left: 5px;
    font-size: 35px;
    color: #006466;
    font-weight: 700;
    text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
}

.skills {
    font-size: 20px;
    margin-left: 75px;
    font-weight: 500;
    /* color: whitesmoke; */
}

.wsu {
    margin-top: 33.2px;
}

.containerAboveEd {
    display: flex;
    /* flex-shrink: 0; */
    align-items: flex-start;
    /* align-items: center; */
    justify-content: center;
    margin-left: 20px;
}

.edContainer {
    /* opacity: 90%; */
    /* background: rgba(141, 144, 144, 0.5); */
    /* background: linear-gradient(180deg, rgba(141, 144, 144, 1) 0%, rgba(145, 145, 145, 0.9699230033810399) 35%, rgba(78, 78, 78, 0.8942927512801996) 100%); */
    width: fit-content;
    border-radius: 5px;
    margin-left: auto;
    margin-right: auto;
    /* margin-top: 40px; */
    margin-bottom: 30px;
    padding: 10px;
}

.topEdContainer {
    display: flex;
    justify-content: space-around;
}

.korchie4 {
    width: 100%;  /* Set width to 100% of its parent */
    height: auto;  /* Auto height to maintain aspect ratio */
    max-width: 300px; /* Limit the maximum width */
    margin-right: 20px;
}

.gcEnd {
    margin-bottom: 20px;
}

.korchie3transback {
    width: 50%;
    height: 50%;
}

/* .d-block {
    height: 400px;
} */

/* .carouselContainer {
    display: flex;
    justify-content: center;
} */

/* working on this */
/* .carouselContainer {
    width: max-content;
    height: 20vh;
} */

.carousel img {
    height: 20vw;
    margin-right: auto;
    margin-left: auto;
}

.carousel {
    margin-bottom: 70px;
}

@media (max-width: 900px) {
    .carousel {
        width: auto;
        height: auto;
    }
}

.emailContact {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 50px;
    margin-bottom: 350px;
}

/* .emailContact a {
    color: rgb(141, 144, 144);

} */

.carousel-indicators {
    margin-bottom: -2rem;
}

.rugsBlerb {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 30px;
    margin-bottom: 20px;
}

.rugOrder {
    color:white;
    font-style: italic;
    font-size: 21px;
    display: flex;
    justify-content: center;
    margin-bottom: 50px;
}

.korchieNPreshNew3 {
    width: 40%;
}

/*  ~~~~~~~~~~~ colors ~~~~~~~~~~~~~~~~~~~~ */
/* 
4d194d purple
3e1f47 violet
312244 darkest violet
272640
212f45
1b3a4b going into teal
1b3a4b dark teal
144552
0b525b --top nav color
065a60
006466 teal */


/* .name {
position: fixed;
top: 0vh;
left: 2vh;
} */



/* mobile view */
@media only screen and (max-width: 768px) {

body {
    font-size: 4vh;
    overflow-x: hidden;
    overflow-y: scroll;
    height: 100vh;
}

.name {
    font-size: 5.5vh;
}

/* .skcircle {
    width: 10vw;
} */

.topNav ul {
    flex-wrap: wrap;
    flex-direction: column;
}

.topNav li {
    flex-basis: 50%;
}

p {
    margin-left: 2vw;
    margin-right: 2vw;
    font-size: 18px;
}


/* .name {
    display: flex;
    justify-content: center;
} */



.korchie4 {
    /* width: auto; */
    height: auto;
    max-width: 300px;
    max-height: 100%;
}

.containerAboveEd {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
}

.mainlinks {
    list-style-type: none;
    background: #0b525b;
    color: white;
    padding: 2vw 2vw;
    border-radius: 10%;
    margin-top: 2vw;
    margin-bottom: 2vw;
}

.homepageContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.amContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.amContainer2 {
    display: flex;
    flex-direction: column;
    margin-left: 0;
    margin-right: 0;
}

.amContainer3 {
    margin-top: 20px;
}

.amContainer5 {
    display: flex;
    flex-direction: column-reverse;
}


.korchieMotorcycle {
    width: 90%;
    margin-bottom: 10px;
}

.edContainer {
    flex-direction: column;
}

.d-block {
    height: auto;
    width: 93vw;
}



.carousel img {
    height: 70%;
    margin-right: auto;
    margin-left: auto;
}

.gitHubLogo {
    padding-left: 5vw;
    height: 4vh;
    width: auto;
}

.socialLinks {
    align-items: center;
    align-self: flex-end;
}

.socialLinks img {
    width: 20vw;
    height: auto;
}

.instagramLogo {
    padding-left: 5vw;
    margin-right: 0;
}

.linkedInLogo {
    margin-left: 0;
}
.emailContact {
    margin-bottom: 24vh;
}

.container2 {
    display: flex;
    justify-content: center;
    align-items: center;
    /* margin-left: 2vw;
    margin-right: 2vw; */
    width: 100%;
    border-radius: 0;
}

.korchieKrittersLogo {
    width: 100vw;
    padding-right: 0;
}

.homepageBlerb {
    font-size: 5.5vh;
}

.homepageWords {
    margin-top: 0vw;
}


.container2 h2 {
    font-size: 9vw;
}

.etsyWords {
    margin-left: 4vw;
}

.korchie2transback {
    width: 100%;
}

.sk_transBack {
    margin-left: 0;
    width: 50%;
    display: block;
    margin: 0 auto;
}

.edTitle {
    margin-top: 0px;
}

.topEdContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.containerAboveEd {
    float: right;
    width: 30%; /* Adjust as necessary */
    max-width: 300px;
    height: auto;
    margin-left: 20px;
}



.korchie4 {
  margin-right: 0;
}

.korchieNPreshNew3 {
    width: 300px;
    height: auto;
}

}

/* desktop view */
@media only screen and (m-width: 768px) {
    /* .topNav {
        position: fixed;
        top: 1vh;
        right: 1vh;     
    } */

    /* a {
        padding: 1.5vh;
    } */

    .logo {
        margin-bottom: 20px;
    }



    .amBlerb {
        margin-top: 55px;
    }

    .amContainer2 p {
        margin-left: 20px;
        margin-right: 20px;
    }

    .amContainer5 {
        margin-bottom: 50px;
    }


    .korchieKrittersLogo {
        padding-right: 100px;
    }



}